// ** React
import React, { useState, useRef, useReducer, useEffect, Fragment } from 'react';

// ** Custom Hooks
import { useDepartmentData } from '../hooks/useDepartmentData';
import { useCourseData } from '../hooks/useCourseData';

// ** MUI Imports
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import Zoom from '@material-ui/core/Zoom';
import FormHelperText from '@material-ui/core/FormHelperText';

// ** Custom Components
import SEO from '../components/layout/seo';
import Container from '../components/layout/container';
import Uploader from '../components/forms/uploader';
import PQueue from 'p-queue';
import slugify from 'slugify';
import Layout from '../containers/layout';

// ** Utils
import sanityClient from '../utils/sanity_client';
import { capitalize } from '../lib/helpers';
import { HERO_FILE_TYPES } from '../lib/constants';
import { useWindowSize } from '../utils/window';

// ** Styling
import { link, gameFormTitle, heroTitle, contactUs } from './class-search-form.module.css';

// ** 3rd party library
import axios from 'axios';
import * as tus from 'tus-js-client';
import { format } from 'date-fns';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#252525',
    },
    secondary: {
      main: '#dd271a',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: '6rem',
    '& > * + *': {
      marginTop: '2rem',
    },
  },
  paper: {
    position: 'absolute',
    width: 'max-content',
    backgroundColor: '#fff',
    color: '#252525',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    backgroundColor: '#fff',
  },
  formControl: {
    minWidth: 120,
    backgroundColor: '#fff',
  },
  label: {
    marginLeft: theme.spacing(1.5),
  },
  formLabel: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    borderRadius: 0,
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, sans-serif;',

    '& .MuiSelect-root': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '1.25rem 2rem 1.25rem 0.75rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
    '& .MuiSvgIcon-root ': {
      fill: '#ee3224 !important',
    },

    '& .MuiListItemText-root': {
      width: 'max-content',
      flex: 'unset',

      [theme.breakpoints.down(675)]: {
        display: 'flex',
        whiteSpace: 'normal',
      },

      '&:nth-child(1)': {
        flexBasis: '80%',
      },

      '&:nth-child(2)': {
        flexBasis: '20%',

        '& > span': {
          textAlign: 'right',
        },
      },

      '& > span': {
        fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, sans-serif;',
        width: 'max-content',
      },
    },
  },
  listItem: {
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, sans-serif;',
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiListItemText-root': {
      display: 'flex',
      whiteSpace: 'normal',

      '&:nth-child(1)': {
        flexBasis: '80%',
      },

      '&:nth-child(2)': {
        flexBasis: '20%',

        '& > span': {
          width: '100%',
          display: 'block',
          textAlign: 'right',
        },
      },

      '& > span': {
        fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, sans-serif;',
        width: 'max-content',
      },
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  smallTopSpacing: {
    marginTop: '0',
  },
  h3: {
    fontSize: '9px',
  },
  snackBar: {
    flexDirection: 'column',
  },
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const fileQueue = new PQueue({
  concurrency: 4,
  interval: 1000 / 25,
});

const ClassSearchForm = (props) => {
  // Styling
  const classes = useStyles();

  // Custom Hooks
  const departments = useDepartmentData();
  const courses = useCourseData();
  const [width] = useWindowSize();

  // Ref
  const courseImageRef = useRef(null);
  const courseRef = useRef(null);
  const schoolRef = useRef(null);

  // ** State
  const [submitting, setSubmitting] = useState(false);
  const [hasBeenSubmit, setHasBeenSubmit] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('Uploading files');
  const [submitStep, setSubmitStep] = useState(null);
  const [completed, setCompleted] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [formErrors, setFormErrors] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState(null);
  const [modalStyle] = useState(getModalStyle);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [filteredCourse, setFilteredCourse] = useState([]);

  // Form Reducer
  const [form, setForm] = useReducer(
    (data, partialData) => ({
      ...data,
      ...partialData,
    }),
    {
      school: null,
      course: null,
      courseNumber: null,
      courseImage: [],
    }
  );

  // Error Reducer
  const [errors, setErrors] = useReducer(
    (data, partialData) => ({
      ...data,
      ...partialData,
    }),
    {
      school: false,
      course: false,
      courseImage: false,
    }
  );

  useEffect(() => {
    if (form.school) {
      const filteredCourse = courses.filter((course) => course.department._id === form.school);
      setFilteredCourse(filteredCourse);
    } else {
      setFilteredCourse([]);
    }
  }, [setFilteredCourse, form.school]);

  const steps = [uploadMessage, 'Submitting profile', 'Complete'];
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const updateCourseFileUploadsCallback = React.useCallback(
    (media, type = 'added') => {
      setErrors({ courseImage: false });

      if (type === 'removed') {
        setForm({ courseImage: [] });

        return;
      }

      if (media && media.length) {
        setForm({ courseImage: media });
      }
    },
    [setForm]
  );

  function updateTextField(event, type, typing = false) {
    const value = event.target.value;

    switch (type) {
      case 'name':
        if (value.length < 4) {
          if (typing) return;
          setErrors({ name: 'Name must be 4 characters or longer!' });
        } else {
          setErrors({ name: false });
        }
        break;
      case 'title':
        if (value.length < 4) {
          if (typing) return;
          setErrors({ title: 'Title must be 4 characters or longer!' });
        } else {
          setErrors({ title: false });
        }
        break;
      case 'school':
        if (value === null || value === '' || value === undefined) {
          if (typing) return;
          setErrors({ school: 'School is required!' });
          setForm({ school: null });
        } else {
          setForm({ school: value, course: null });
          setErrors({ school: false });
        }
        break;
      case 'course':
        const course = value && JSON.parse(value); // eslint-disable-line
        if (value === '' || value === undefined || course?._id === null || course?._id === '') {
          if (typing) return;
          setErrors({ course: 'Course is required!' });
          setForm({ course: null, courseNumber: null });
        } else {
          setErrors({ course: false });
          setForm({ course: course._id, courseNumber: course.courseNumber });
        }
        break;
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let localFormErrors = [];

    if (errors.school || errors.course || form.courseImage.length === 0) {
      if (errors.school) {
        schoolRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (errors.course) {
        courseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (form.courseImage.length === 0) {
        courseImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setErrors({ courseImage: true });
      }
      return;
    }

    setSubmitting(true);
    setHasBeenSubmit(true);
    setModalOpen(true);

    let classForm = document.getElementById('classForm');
    const FD = new FormData(classForm);

    let uploadedFiles = [];
    let error = false;

    const filesUpload = [...form['courseImage']];

    if (filesUpload.length > 0) {
      // setSubmitStep(0);
      setUploadMessage(`Submitting files (0/${filesUpload.length})`);
      // console.log('submiting files filesUpload: ', filesUpload);
      let progress = 0;

      filesUpload.forEach((f, idx) => {
        setCompleted(0);
        // console.log('filesUpload forEach: ', f, idx);
        setCustomMeta(FD, f);
        // console.log('adding file to queue ', f);
        // // console.log('queue size', fileQueue.size());
        fileQueue.add(async () => {
          setFileUploadStatus({ [f.meta.id]: 'uploading' });
          progress += 1;
          setUploadMessage(`Submitting files (${progress}/${filesUpload.length})`);

          const metaType = f.meta.type.includes('video') ? 'video' : f.meta.type;

          startFakeUploadProgress();

          switch (metaType) {
            case 'video':
              await uploadToVimeo(f)
                .then(({ url }) => {
                  console.log('return from upload to vimeo func', url);

                  setFileUploadStatus({ [f.meta.id]: 'complete' });

                  const _name = f.meta.customMeta._name; // internal use only for the uploader field name

                  if (_name === 'courseVideo') {
                    uploadedFiles.push(prepareMediasSanityObject(null, null, metaType, f, url));
                  }
                })
                .catch((e) => {
                  // LogRocket.track('Form Error');
                  // LogRocket.captureException(e);
                  console.error('ERROR uploading video:', e);
                  localFormErrors.push(
                    `There was an error uploading your video ${f.meta.name} please try a smaller file or a faster internet connection`
                  );
                  error = true;
                  setFileUploadStatus({ [f.meta.id]: 'failed' });
                  setSubmitting(false);
                });
              break;
            default:
              await uploadToSanity(f, 'image')
                .then(({ id, type }) => {
                  setFileUploadStatus({ [f.meta.id]: 'complete' });
                  // get name meta data
                  const _name = f.meta.customMeta._name; // internal use only for the uploader field name
                  // console.log('meta in upload to sanity', f.meta);
                  // console.log('meta.name in upload to sanity', name);

                  if (_name === 'thumbnail') {
                    uploadedFiles.push(prepareMediasSanityObject(id, id, type, f));
                  }
                })
                .catch((e) => {
                  // LogRocket.track('Form Error');
                  // LogRocket.captureException(e);
                  console.error('ERROR uploading image:', e);
                  localFormErrors.push(
                    `There was an error uploading your image ${f.meta.name} please try a smaller file or a faster internet connection`
                  );
                  error = true;
                  setFileUploadStatus({ [f.meta.id]: 'failed' });
                  setSubmitting(false);
                });
              endFakeUploadProgress();
          }
        });
      });
    }

    await fileQueue.onIdle();
    if (error) {
      setFormErrors(localFormErrors);
      setModalOpen(false);
      return;
    }

    const profileDocument = {
      _type: 'course',
      _id: form.course,
      department: FD.get('school') ? { _type: 'reference', _weak: false, _ref: FD.get('school') } : undefined,
      courseNumber: form.courseNumber,
      thumbnail: uploadedFiles && uploadedFiles.length ? uploadedFiles[0] : undefined,
    };

    sanityClient
      .patch(profileDocument._id)
      .set(profileDocument)
      .commit()
      .then((res) => {
        // setSubmitStep(3);
        classForm.reset();
        // reset form state
        setForm({
          school: null,
          course: null,
          courseNumber: null,
          courseImage: [],
        });
        // reset errors state
        setErrors({
          school: false,
          course: false,
          courseImage: false,
        });
        updateCourseFileUploadsCallback({ type: 'removed' });
        setIsSubmitted(true);
        window.scrollTo({
          top: 0,
          left: 100,
          behavior: 'smooth',
        });
      })
      .catch((e) => {
        // LogRocket.track('Form Error');
        // LogRocket.captureException(e);
        console.error(e);
        setFormErrors([
          ...localFormErrors,
          'Something went wrong with uploading your profile.  Please try again later.',
        ]);
        // setSubmitStep(null);
        setModalOpen(false);
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
        setModalOpen(false);
        setCompleted(0);
      });
  }

  async function uploadToSanity(f, type) {
    const newFileName = `${format(new Date(), 'yyyy_MM_dd_HH:mm')}-${f.file.name}`;
    const r = await sanityClient.assets.upload(type, f.file, { filename: newFileName });
    return { id: r._id, type: r.mimeType };
  }

  function startFakeUploadProgress() {
    if (completed > 100) {
      setCompleted(0);
      setBuffer(10);
    } else {
      const diff = Math.random() * 10;
      const diff2 = Math.random() * 10;
      setCompleted(completed + diff);
      setBuffer(completed + diff + diff2);
    }
  }

  async function uploadToVimeo(f) {
    const size = f.meta.size;
    const body = {
      upload: {
        approach: 'tus',
        size,
      },
      name: f.meta?.customMeta?.title,
      description: f.meta?.customMeta?.description,
    };

    const headers = {
      Authorization: `Bearer ${process.env.GATSBY_VIMEO_APP_API_KEY}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    };

    const presignedLinkResponse = await axios.post(`https://api.vimeo.com/me/videos`, body, {
      headers,
    });

    return new Promise((resolve, reject) => {
      const uploader = new tus.Upload(f.file, {
        uploadUrl: presignedLinkResponse?.data?.upload?.upload_link,
        endpoint: presignedLinkResponse?.data?.upload?.upload_link,
        metadata: {
          filename: f.meta.name,
          filetype: f.meta.type,
        },
        onError: function (error) {
          console.log('Failed because: ' + error);
          reject(error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + '%');
        },
        onSuccess: async function () {
          console.log('Download %s from %s', uploader.file.name, uploader.url);

          // Move to specific folder
          try {
            await axios.put(
              `https://api.vimeo.com/me/projects/14871385${presignedLinkResponse?.data?.uri}`,
              {},
              {
                headers,
              }
            );

            // Return vimeo link
            resolve({ url: presignedLinkResponse?.data?.link });
          } catch (error) {
            console.log('Error move folder:', error);
          }
        },
      });
      uploader.start();
    });
  }

  function endFakeUploadProgress() {
    setCompleted(100);
  }

  function setCustomMeta(FD, f) {
    const entries = FD.entries();
    const fileId = f.meta.id.split('-')[0];

    let entry = entries.next();
    f.meta.customMeta = {};
    while (!entry.done) {
      const [type, fileMetaId, _, name] = entry.value[0].split('-');
      if (type === 'filemeta' && fileMetaId === fileId && entry.value[1]) {
        // build special object for assetCategory ref value
        if (name === '_category') {
          f.meta.customMeta.assetCategory = {
            _ref: entry.value[1],
            _type: 'reference',
            _weak: true,
          };
        } else if (name === 'description') {
          f.meta.customMeta.description = [
            {
              _type: 'block',
              _key: 'student-' + fileId + '-metadescriptionblock-' + fileId,
              style: 'normal',
              children: [
                {
                  _type: 'span',
                  _key: 'student-' + fileId + '-metadescription-' + fileId,
                  marks: [],
                  text: entry.value[1] || '',
                },
              ],
              markDefs: [],
            },
          ];
        } else {
          f.meta.customMeta[name] = entry.value[1];
        }
      }
      entry = entries.next();
    }
  }

  function prepareMediasSanityObject(courseId, ref, type, f, url) {
    switch (type) {
      case 'video':
        return {
          _key: 'course-video-' + f.meta.id,
          _type: 'video',
          caption: f.meta.caption,
          title: f.meta.customMeta.title || 'course video',
          url: url,
          ...f.meta.customMeta,
        };
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return {
          _key: 'course-image' + courseId.replace(/[^\d\w]/gi, ''),
          _type: 'figure',
          image: { _type: 'image', asset: { _type: 'reference', _ref: ref } },
          alt: f.meta.customMeta.caption || f.meta.customMeta.title || 'course image',
          imageAttribution: f.meta.customMeta.name || undefined,
          ...f.meta.customMeta,
        };
      default:
        return {
          _key: 'course-file' + courseId.replace(/[^\d\w]/gi, ''),
          _type: 'fileUpload',
          file: { _type: 'file', asset: { _type: 'reference', _ref: ref } },
          alt: f.meta.customMeta.caption || f.meta.customMeta.title || 'course image',
          ...f.meta.customMeta,
        };
    }
  }

  function prepareHeroSanityObject(courseId, ref, type, f, url) {
    // we disabled video upload file formats for hero but this logic remains in case we ever turn it on again
    if (type === 'video') {
      return {
        _key: 'course-' + courseId.replace(/[^\d\w]/gi, '') + '-hero-video-' + f.meta.id,
        _type: 'video',
        title: f.meta.customMeta.title || 'hero video',
        url: url,
        ...f.meta.customMeta,
      };
    } else {
      return {
        _key: 'course-hero-image' + courseId.replace(/[^\d\w]/gi, ''),
        _type: 'sectionHero',
        backgroundImage: {
          _type: 'backgroundImage',
          asset: { _type: 'reference', _ref: ref },
          alt: f.meta.customMeta.caption || 'caption',
        },
        heroImageCaption: f.meta.customMeta.title || 'hero image',
        heroImageAttribution: f.meta.customMeta.name || undefined,
      };
    }
  }

  function removeError(error) {
    const newErrors = formErrors.filter((element) => element !== error);

    setFormErrors(newErrors);
  }

  return (
    <Layout
      //   dark={true}
      // headerBackgroundImage={headerBackgroundImage}
      smallHeader
    >
      <SEO title="Course Catalog Asset Upload Form" noindex={true} />
      <Container narrower>
        <ThemeProvider theme={theme}>
          {/*--------------------
            Step 1: Profile info
            ------------------------*/}
          <h1 className={gameFormTitle}>Course Catalog Asset Upload Form</h1>
          {isSubmitted ? (
            <div style={{ textAlign: 'center', marginTop: '3em' }}>
              <h3>Your profile was submitted successfully.</h3>
              <div>
                <Zoom in={true}>
                  <CloudDoneIcon style={{ fontSize: 130, color: 'var(--color-neon-green)' }} />
                </Zoom>
              </div>
              <Button
                onClick={() => setIsSubmitted(false)}
                fullWidth
                type="submit"
                disabled={submitting}
                size="large"
                variant="contained"
                color="primary"
              >
                SUBMIT ANOTHER COURSE
              </Button>
            </div>
          ) : (
            <div>
              <form id="classForm" onSubmit={handleSubmit} className={classes.form}>
                <ul>
                  <li>Please complete the submission form below.</li>
                  <li>Items marked with an * are required.</li>
                  <li>Please make sure all information is entered correctly before submitting.</li>
                </ul>

                <FormControl error={!!errors.school} required fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="school" className={classes.label}>
                    School
                  </InputLabel>
                  <Select
                    ref={schoolRef}
                    onBlur={(val) => updateTextField(val, 'school')}
                    onChange={(val) => {
                      updateTextField(val, 'school');
                    }}
                    inputProps={{
                      name: 'school',
                      id: 'school',
                    }}
                    className={classes.select}
                    variant="filled"
                    fullWidth
                    MenuProps={MenuProps}
                  >
                    {departments &&
                      departments.map((department) => (
                        <MenuItem className={classes.listItem} key={department._id} value={department._id}>
                          {capitalize(department.title)}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.school && <FormHelperText>School is required!</FormHelperText>}
                </FormControl>

                {width > 675 ? (
                  <FormControl error={!!errors.course} required fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="course" className={classes.label}>
                      Course
                    </InputLabel>
                    <Select
                      ref={courseRef}
                      onBlur={(val) => updateTextField(val, 'course')}
                      onChange={(val) => updateTextField(val, 'course')}
                      inputProps={{
                        name: 'course',
                        id: 'course',
                      }}
                      variant="filled"
                      fullWidth
                      className={classes.select}
                      MenuProps={MenuProps}
                      style={{ minWidth: 0 }}
                    >
                      {filteredCourse.length > 0
                        ? filteredCourse.map((courseItem) => (
                            <MenuItem
                              className={classes.listItem}
                              key={courseItem._id}
                              value={JSON.stringify(courseItem)}
                            >
                              <ListItemText primary={courseItem.title} />
                              <ListItemText primary={courseItem.courseNumber} />
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    {errors.course && <FormHelperText>Course is required!</FormHelperText>}
                  </FormControl>
                ) : (
                  <FormControl error={!!errors.course} required fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="course" className={classes.label}>
                      Course
                    </InputLabel>
                    <Select
                      ref={courseRef}
                      onBlur={(val) => updateTextField(val, 'course')}
                      onChange={(val) => updateTextField(val, 'course')}
                      inputProps={{
                        name: 'course',
                        id: 'course',
                      }}
                      variant="filled"
                      fullWidth
                      className={classes.select}
                      MenuProps={MenuProps}
                      style={{ minWidth: 0 }}
                    >
                      {filteredCourse.length > 0
                        ? filteredCourse.map((courseItem) => (
                            <MenuItem
                              className={classes.listItem}
                              key={courseItem._id}
                              value={JSON.stringify(courseItem)}
                            >
                              <ListItemText primary={`${courseItem.title} - ${courseItem.courseNumber}`} />
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    {errors.course && <FormHelperText>Course is required!</FormHelperText>}
                  </FormControl>
                )}

                <>
                  <h3 className={heroTitle}>Submit course image</h3>
                  <ul ref={courseImageRef}>
                    <li>The image must be a square (1:1 aspect ratio)</li>
                    <li>Please submit a single image</li>
                    <li>Files accepted: jpg, png</li>
                  </ul>
                  <Uploader
                    acceptedFileTypes={HERO_FILE_TYPES}
                    callback={updateCourseFileUploadsCallback}
                    error={errors.courseImage}
                    errorText="Submit a Course Image"
                    fileUploadStatus={fileUploadStatus}
                    label="Image here"
                    limit={1}
                    multiple={false}
                    name="thumbnail"
                    required
                  />
                </>

                <p className={contactUs}>
                  Need help? Contact us at&nbsp;
                  <a href="mailto:websupport@academyart.edu" title="email websupport@academyart.edu" className={link}>
                    websupport@academyart.edu
                  </a>
                  .
                </p>
                <Button fullWidth type="submit" disabled={submitting} size="large" variant="contained" color="primary">
                  {submitting ? (
                    <>
                      <CircularProgress size={20} color={'primary'} style={{ marginRight: '10px' }} />
                      Submitting
                    </>
                  ) : (
                    'Submit profile'
                  )}
                </Button>
              </form>
            </div>
          )}
          {formErrors.length > 0 ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={hasBeenSubmit}
              onClose={null}
              className={classes.snackBar}
            >
              <>
                {formErrors.map((formError, idx) => (
                  <Alert key={idx} onClose={() => removeError(formError)} severity="error">
                    {formError}
                  </Alert>
                ))}
              </>
            </Snackbar>
          ) : (
            <Modal open={modalOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
              <div style={modalStyle} className={classes.paper}>
                <h2 id="simple-modal-title">Uploading files...</h2>
                <p id="simple-modal-description">Please be patient if you are uploading large files.</p>
                <Stepper activeStep={submitStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <LinearProgress variant="buffer" color={'secondary'} value={completed} valueBuffer={buffer} />
              </div>
            </Modal>
          )}
        </ThemeProvider>
      </Container>
    </Layout>
  );
};

export default ClassSearchForm;
